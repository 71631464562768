import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const DEFAULT_LANGUAGE = "en";

const VARIANT_KEY = "sso-language-variant";
const LOCALIZED_VARIANT_VALUE = "localized";
const ORIGINAL_VARIANT = "original";

export const getOrCreateVariant = (): string => {
  let variant = localStorage.getItem(VARIANT_KEY);
  if (!variant) {
    variant = Math.random() < 0.5 ? LOCALIZED_VARIANT_VALUE : ORIGINAL_VARIANT; // 50/50 split
    localStorage.setItem(VARIANT_KEY, variant);
  }
  return variant;
};

export const getAppLanguage = () => {
  return i18n.language;
};

export const isLocalized = () => getOrCreateVariant() === LOCALIZED_VARIANT_VALUE;

export const initializeI18n = () => {
  i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      fallbackLng: DEFAULT_LANGUAGE,
      debug: false,
      backend: {
        loadPath: "/locales/{{lng}}/{{ns}}.json",
      },
    });

  const variant = getOrCreateVariant();

  if (variant !== LOCALIZED_VARIANT_VALUE) {
    return;
  }

  const detector = new LanguageDetector();
  detector.init();
  let detectedLanguage = detector.detect();


  if (detectedLanguage === undefined) {
    return DEFAULT_LANGUAGE;
  }

  if (Array.isArray(detectedLanguage)) {
    detectedLanguage = detectedLanguage[0];
  }

  /**
   * Extract the language code from the detected language (detected language will often include the region code)
   * @param detectedLanguage - The detected language
   * @returns - The extracted language code
   */
  const extractLanguageCode = (detectedLanguage: string) => {
    const language = detectedLanguage.split("-")[0];
    
    switch(language) {
      case "ko":
        return "ko";
      case "es":
        return "es";
      case "pt":
        return "pt";
      case "it":
        return "it";
      case "zh":
        return "zh_TW";
      case "fr":
        return "fr";
      case "de":
        return "de";
      case "tr":
        return "tr";
      default:
        return DEFAULT_LANGUAGE; // Fallback to DEFAULT_LANGUAGE if not supported
    }
  }

  // Get the normalized language
  const normalizedLanguage = extractLanguageCode(detectedLanguage); 

  i18n.changeLanguage(normalizedLanguage);
};

// To downlaod the translations from lokalize
// >> lokalise2 file download --unzip-to /Users/aj/Code/sso/client/public/locales --format json --project-id <PROJECT_ID> --token <TOKEN>
